import React, { Component } from 'react';
import PropTypes from 'prop-types';
import block from 'bem-cn';
import { connect } from 'react-redux';
import {
  Button,
  Form,
  Icon,
  Loader,
} from 'semantic-ui-react';
import { withCustomRouter } from 'HOC';
import { Formik } from 'formik';
import {
  setInsurerValues,
  setInsuredValues,
  getError,
  setInsuredIsSame,
  resetValidationTerrorist,
  checkAccountNumbers,
  changeDisableInsuredIsSame,
  checkAgent,
} from 'redux/rootActions';
import { getMainRequestId } from 'redux/rootSelectors';
import {
  checkSnils,
  getErrorsFromValidationError,
  setInitialValues,
  setSomeFieldsValues,
  getAddress,
  getProductConfig,
  getBankList,
  getFmsUnitList,
  scrollToError,
  simpleDate,
  getAgentChecking,
} from 'helpers';

import {
  LANG_DICTIONARY,
  INSURER_FIELDS,
  INSURED_FIELDS_LIVING,
  BANK_FIELDS,
  RELATIONSHIP_FIELD,
  ROUTES,
  NAMES_GROUP_SETTINGS,
  INPUT_NAMES,
  TEST_DATA_ENUMS,
  PROCESSING_STAGES,
  SYSTEM_DATE_TO,
  TYPE_PERSON,
} from 'consts';

import {
  DocumentBlock,
  ContactBlock,
  FormSection,
  InnSnilsBlock,
  BankBlock,
  PersonBlock,
  RelationShipBlock,
  RequiredLabel,
  ErrorMessage,
  CustomCheckBox,
  AddressBlockCommon,
  AgentCheckingErrorPopup,
} from 'components';
import { TestDataGenerator } from 'containers';

import { Collapse } from 'reactstrap';
import moment from 'moment';
import Schema from './schema';
import './styles.scss';

const {
  beneficiaries: beneficiariesUrl,
  insuredData: insuredDataUrl,
  insurerData: insurerDataUrl,
  preliminaryCalculation: preliminaryCalculationUrl,
} = ROUTES;

const defaultProps = {
  isLoading: false,
  SetInsuredValues: null,
  SetInsurerValues: null,
  SetInsuredIsSame: null,
  history: null,
  additionalParams: null,
  insurerValues: null,
  insuredValues: null,
  isInsuredIsSame: true,
  error: null,
  snilsError: null,
  GetError: null,
  passportError: null,
  stage: 1,
  scenario: '',
  isLoadingTerror: false,
  isValidTerrorist: true,
  hideInn: true,
  hideSnils: true,
  checkPassportCovid: false,
  restrictions: {},
  productTerms: [],
  productPeriod: 0,
  regDate: '',
  checkAccountNumbersError: false,
  checkNumbers: () => null,
  checkAgentAction: () => null,
  hideBankInfo: false,
  isInsurerIsInsuredSetting: false,
  isValidAddress: true,
  isListBlackAddress: false,
  blackAddressInfo: {},
  insLineIszObjects: [],
  productVersion: 0,
  birthDate: null,
  isDisableInsuredIsSame: false,
  ChangeDisableInsuredIsSame: null,
  insuredBirthDate: '',
  policySum: 0,
  mainRequestId: 0,
  insurerFields: {},
  isCopyContract: false,
};

const propTypes = {
  checkNumbers: PropTypes.bool,
  checkAccountNumbersError: PropTypes.func,
  restrictions: PropTypes.object,
  isLoading: PropTypes.bool,
  SetInsuredValues: PropTypes.func,
  SetInsurerValues: PropTypes.func,
  SetInsuredIsSame: PropTypes.func,
  history: PropTypes.object,
  additionalParams: PropTypes.object,
  insurerValues: PropTypes.object,
  insuredValues: PropTypes.object,
  isInsuredIsSame: PropTypes.bool,
  error: PropTypes.string,
  snilsError: PropTypes.string,
  GetError: PropTypes.func,
  passportError: PropTypes.string,
  stage: PropTypes.number,
  match: PropTypes.object.isRequired,
  scenario: PropTypes.string,
  isLoadingTerror: PropTypes.bool,
  isValidTerrorist: PropTypes.bool,
  hideInn: PropTypes.bool,
  hideSnils: PropTypes.bool,
  checkPassportCovid: PropTypes.bool,
  productTerms: PropTypes.arrayOf(PropTypes.object),
  productPeriod: PropTypes.number,
  regDate: PropTypes.string,
  hideBankInfo: PropTypes.bool,
  isInsurerIsInsuredSetting: PropTypes.bool,
  isValidAddress: PropTypes.bool,
  isListBlackAddress: PropTypes.bool,
  blackAddressInfo: PropTypes.object,
  insLineIszObjects: PropTypes.arrayOf(PropTypes.object),
  productVersion: PropTypes.number,
  birthDate: PropTypes.object,
  isDisableInsuredIsSame: PropTypes.bool,
  ChangeDisableInsuredIsSame: PropTypes.func,
  insuredBirthDate: PropTypes.string,
  checkAgentAction: PropTypes.func,
  policySum: PropTypes.number,
  mainRequestId: PropTypes.number,
  insurerFields: PropTypes.object,
  isCopyContract: PropTypes.bool,
};

const b = block('insurerData');
const {
  NEXT,
  DOCUMENTS,
  REGISTRATION_ADDRESS,
  LIVING_ADDRESS,
  SAME_ADDRESS,
  IS_SAME,
  CONTACT_DATA,
  BANK_DETAILS,
  INSURED_AGE_ERROR,
  DOCUMENT_TYPE_PASSPORT,
  INSURER_AND_INSURED_CANNOT_MATCH_AS_THERE_ARE_DIFFERENT_DATE_OF_BIRTH,
  SINGULAR_INSURED,
  INSURER,
} = LANG_DICTIONARY;

const { insurerAgent, insuredPerson } = NAMES_GROUP_SETTINGS;

const settedInitialValues = () => ({
  documentType: DOCUMENT_TYPE_PASSPORT,
  country: 'Россия',
  countryLiving: 'Россия',
  livingAddressIsSame: true,
  issuedByAccepted: false,
});

class insurerData extends Component {
  constructor(props) {
    super(props);
    const {
      stage,
      history,
      additionalParams: { isInsured },
      match: { params: { id } },
      scenario,
    } = props;
    const minStage = isInsured ? PROCESSING_STAGES.insuredData : PROCESSING_STAGES.insurerData;
    const redirectPath = isInsured ? insurerDataUrl : preliminaryCalculationUrl;
    const groupSettings = isInsured ? insuredPerson : insurerAgent;
    const productConfig = getProductConfig(scenario, groupSettings);

    this.state = {
      ...productConfig,
      ageError: null,
    };

    if (stage < minStage) {
      history.push(`${redirectPath}/${id}`);
    }
  }

  componentDidMount() {
    const {
      SetInsuredValues,
      insuredBirthDate,
      insurerFields,
      isInsuredIsSame,
      additionalParams: { isInsured },
    } = this.props;

    const { isBank } = this.state;

    const bankValues = isBank ? setInitialValues(BANK_FIELDS) : null;
    const relationship = isInsured ? setInitialValues(RELATIONSHIP_FIELD) : null;

    if (isInsured && isInsuredIsSame) {
      SetInsuredValues({
        ...setInitialValues(INSURER_FIELDS),
        ...setInitialValues(INSURED_FIELDS_LIVING),
        ...settedInitialValues(isInsured),
        ...bankValues,
        ...relationship,
        birthDate: insuredBirthDate,
        ...insurerFields,
      });
    }
  }

  nextStep = (values) => {
    const {
      match: { params: { id } },
      history,
      additionalParams: { isInsured },
      isInsuredIsSame,
      insurerValues,
      insuredValues,
      birthDate,
      ChangeDisableInsuredIsSame,
      SetInsuredIsSame,
      isCopyContract,
    } = this.props;

    const valuesForSave = isInsured && isInsuredIsSame ? insurerValues : values;
    this.saveFormValues(valuesForSave);

    if (this.validateAgeRestrictions(values)) {
      const path = isInsured ? beneficiariesUrl : insuredDataUrl;
      history.push(`${path}/${id}`);
    }

    if (!isInsured) {
      const insuredBirthInsurer = simpleDate(birthDate);
      const insuredIsSameValue = !isCopyContract ||
          (moment(values.birthDate).format(SYSTEM_DATE_TO) === insuredBirthInsurer &&
        insuredValues.serial === insurerValues.serial &&
        insuredValues.passportNumber === insurerValues.passportNumber);

      SetInsuredIsSame(insuredIsSameValue);
      ChangeDisableInsuredIsSame(!insuredIsSameValue);
    }
  };

  saveFormValues = (values) => {
    const {
      SetInsuredValues,
      SetInsurerValues,
      additionalParams: { isInsured },
    } = this.props;
    const save = isInsured ? SetInsuredValues : SetInsurerValues;
    save(values);
  }

  validateAgeRestrictions = () => {
    const restriction = this.getInsuredRestriction();

    if (!restriction) {
      this.setState({ ageError: INSURED_AGE_ERROR });

      return false;
    }

    return true;
  }

  getInsuredRestriction = () => {
    const {
      insLineIszObjects,
      productVersion,
      restrictions: { insured },
    } = this.props;

    return insLineIszObjects.length ?
      insured.find(({ insLineIszObjectId }) => insLineIszObjectId === productVersion) :
      insured[0];
  }

  validateAgent = (values, next) => {
    const {
      checkAgentAction,
      match: { params: { id: productId } },
      policySum,
      additionalParams: { isInsured },
      isInsuredIsSame,
      mainRequestId,
    } = this.props;

    if (isInsuredIsSame && isInsured) {
      next(values);

      return;
    }

    const body = {
      agent: getAgentChecking(values, values.livingAddressIsSame),
      productId: Number(productId),
      agentType: isInsured ? TYPE_PERSON.insured : TYPE_PERSON.insurer,
      amount: policySum,
      mainRequestId: mainRequestId || undefined,
    };

    checkAgentAction({
      data: [{
        agentName: isInsured ? SINGULAR_INSURED : INSURER,
        body,
      }],
      next: () => next(values),
    });
  }

  validateForm = (values, isExecution) => {
    const {
      additionalParams: { isInsured },
      isInsuredIsSame,
      productTerms,
      productPeriod,
      regDate,
      checkAccountNumbersError,
      checkNumbers,
      hideBankInfo,
      checkPassportCovid,
    } = this.props;

    const { documentType, birthDate } = values;
    const { isBank } = this.state;
    const { livingAddressIsSame } = values;
    const disableValidation = isInsured && isInsuredIsSame;
    const notOnlyPassport = documentType > 1;
    const term = productTerms.find((item) => item.termId === productPeriod);

    try {
      Schema({
        isCheckPasswordCovid: checkPassportCovid,
        disableValidation,
        addressIsSame: livingAddressIsSame,
        isBank: isBank && !hideBankInfo,
        isInsured,
        isInsuredIsSame,
        restrictions: this.getInsuredRestriction(),
        notOnlyPassport,
        term: term.value,
        regDate,
        checkAccountNumbersError,
        checkNumbers,
        birthDate,
      })
        .validateSync({ ...values }, { abortEarly: false });

      if (isExecution) {
        this.validateAgent(values, this.nextStep);
      }

      return {};
    } catch (error) {
      const formatErrors = getErrorsFromValidationError(error);

      const isError = Object.values(formatErrors).some(Boolean);

      if (isError) {
        if (isExecution) {
          scrollToError(formatErrors);
        }

        return formatErrors;
      }

      if (isExecution) {
        this.validateAgent(values, this.nextStep);
      }

      return {};
    }
  };

  renderInitialFields = (hardReset) => {
    const {
      additionalParams: { isInsured },
      birthDate,
      insurerValues,
      insuredValues,
      isInsurerIsInsuredSetting,
      insurerFields,
    } = this.props;
    const { isBank } = this.state;

    const checkInsurerIsInsured = () => {
      if (isInsured) {
        return birthDate;
      }

      return isInsurerIsInsuredSetting ? birthDate : null;
    };

    const formValues = isInsured ? insuredValues : insurerValues;
    const bankValues = isBank ? setInitialValues(BANK_FIELDS) : null;
    const relationship = isInsured ? setInitialValues(RELATIONSHIP_FIELD) : null;
    const initialValues = {
      ...setInitialValues(INSURER_FIELDS),
      ...setInitialValues(INSURED_FIELDS_LIVING),
      ...bankValues,
      ...relationship,
      ...settedInitialValues(isInsured),
      birthDate: checkInsurerIsInsured(),
      ...insurerFields,
    };

    if (hardReset) return initialValues;

    return formValues || initialValues;
  };

  renderAddressCheckbox = (
    {
      values,
      onChange,
      onBlur,
    },
  ) => (
    <Form.Field className="insurerData__addressCheckboxWrap">
      <CustomCheckBox
        name="livingAddressIsSame"
        className="insurerData__addressCheckbox"
        checked={values.livingAddressIsSame}
        onChange={(name, checked) => {
          onChange({
            target: {
              value: !checked,
              name,
            },
          });
        }}
        onBlur={onBlur}
      />
      <p>{SAME_ADDRESS}</p>
    </Form.Field>
  );

  setAddress = (setFieldValue, isLiving) => (values) => {
    const addressList = getAddress(values, isLiving);
    setSomeFieldsValues(addressList, setFieldValue);
  };

  setBankValues = (setFieldValue) => (values) => {
    const bankList = getBankList(values);
    setSomeFieldsValues(bankList, setFieldValue);
  };

  setPassportValues = (setFieldValue) => (values) => {
    const fmsUnits = getFmsUnitList(values);
    setSomeFieldsValues(fmsUnits, setFieldValue);
  }

  setInsuredIsSame = (checkBoxValue) => {
    const { SetInsuredIsSame } = this.props;
    SetInsuredIsSame(checkBoxValue);
  };

  validateSnils = (value) => {
    const { snilsError, GetError } = this.props;
    const { result, error } = checkSnils(value);

    if (snilsError !== error) GetError(error, 'snilsError');

    return result;
  };

  isShow = () => {
    const {
      additionalParams: {
        isInsured,
      },
      isInsuredIsSame,
      isInsurerIsInsuredSetting,
    } = this.props;

    if (!isInsured) return true;

    return !isInsurerIsInsuredSetting && !isInsuredIsSame;
  };

  onChangeValue = (setFieldValue, handleChange) => (e) => {
    const { target } = e;
    const { name } = target;

    if (target) {
      if (name.endsWith(INPUT_NAMES.issuedBy)) {
        setFieldValue('issuedByAccepted', false);
      }
    }

    handleChange(e);
  }

  handleClickInsuredIsSame = () => {
    const { isDisableInsuredIsSame } = this.props;

    if (isDisableInsuredIsSame) {
      this.setState({
        errorInsuredIsSame:
        INSURER_AND_INSURED_CANNOT_MATCH_AS_THERE_ARE_DIFFERENT_DATE_OF_BIRTH,
      });

      clearTimeout(this.timeoutInsuredIsSame);
      this.timeoutInsuredIsSame = setTimeout(() => this.setState({ errorInsuredIsSame: '' }), 5000);
    }
  };

  render() {
    const {
      isLoading,
      snilsError,
      error: serverError,
      additionalParams: { isInsured },
      isInsuredIsSame,
      insuredValues,
      passportError,
      isLoadingTerror,
      isValidTerrorist,
      hideInn,
      hideSnils,
      checkPassportCovid,
      checkNumbers,
      isInsurerIsInsuredSetting,
      isValidAddress,
      isListBlackAddress,
      blackAddressInfo,
      scenario,
      isDisableInsuredIsSame,
      hideBankInfo,
      insurerFields,
    } = this.props;

    const {
      isPerson,
      isDocuments,
      isAddress,
      isAddressLiving,
      isInnSnils,
      isContacts,
      isBank,
      ageError,
      errorInsuredIsSame,
    } = this.state;

    const isInnSnilsBlock = isInnSnils && (!hideInn || !hideSnils);
    const isControlDisabled = isInsurerIsInsuredSetting || isInsured;
    const formType = isInsured ? TEST_DATA_ENUMS.insured : TEST_DATA_ENUMS.insurer;
    const disableFIO = Boolean(insurerFields?.firstName && insurerFields?.lastName);

    return (
      <div className={b('formContainer')}>
        <AgentCheckingErrorPopup />
        <Formik
          initialValues={this.renderInitialFields()}
          enableReinitialize
          validate={(values) => this.validateForm(values)}
          onSubmit={(values) => {
            const isInsuredIsInsurer = isInsuredIsSame && isInsured;
            const isValidRemoteCheck = isInsuredIsInsurer || (isValidTerrorist && isValidAddress);

            const isPreValid = !passportError &&
              !snilsError &&
              isValidRemoteCheck;

            if (isPreValid) this.nextStep(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
            setValues,
          }) => {
            const inputProps = {
              values,
              errors,
              touched,
              onChange: this.onChangeValue(setFieldValue, handleChange),
              onBlur: handleBlur,
              setFieldValue,
              setFieldTouched,
              setValues,
              onKeyDown: (event) => {
                if (event?.target && event.target.name.endsWith(INPUT_NAMES.mobilePhone)) {
                  const value = event.target.value.replace(new RegExp('\\+7\\(|\\)|-|_', 'g'), '');
                  if (event.key !== '9' && value.length === 0) {
                    event.preventDefault();
                    setFieldValue(event.target.name, '');
                  }
                }
              },
            };

            const errorWarning = serverError || ageError;

            return (
              <Form onSubmit={handleSubmit} loading={isLoading}>
                <div className={b('formContainer', 'inputs')}>
                  {
                    isInsured && (
                      <div className="formContainer__formSection">
                        <Form.Field className="insurerData__addressCheckboxWrap">
                          <CustomCheckBox
                            name="insuredIsSame"
                            className="insurerData__addressCheckbox"
                            checked={isInsurerIsInsuredSetting || isInsuredIsSame}
                            disabled={isDisableInsuredIsSame || isInsurerIsInsuredSetting}
                            isShowDisabled
                            onChange={(name, checked) => this.setInsuredIsSame(
                              !checked,
                            )}
                            onClick={(...args) => !isInsurerIsInsuredSetting
                                && this.handleClickInsuredIsSame(...args)}
                          />
                          <p>
                            {IS_SAME}
                          </p>
                        </Form.Field>
                        <Collapse isOpen={Boolean(errorInsuredIsSame)}>
                          <div className={b('insured-is-same-error')}>
                            <Icon name="warning" />
                            <p className={b('insured-is-same-error-text')}>{errorInsuredIsSame}</p>
                          </div>
                        </Collapse>
                      </div>
                    )
}
                  {
                    this.isShow()
                      && (
                      <>
                        <RequiredLabel />
                        {
                          isInsured && (
                          <div className="formContainer__formSection">
                            <RelationShipBlock
                              className={b('relationship')}
                              inputProps={inputProps}
                            />
                            </div>
                          )
                        }
                        <div className="formContainer__check-data">
                          {isLoadingTerror && (
                            <>
                              <p>Проверка данных...</p>
                              <Loader active inline size="tiny" />
                            </>
                          )}
                        </div>
                        {
                          isPerson && (
                            <PersonBlock
                              isInsured={isInsured}
                              inputProps={inputProps}
                              disableBirthdate={isControlDisabled}
                              checkPassportCovid={checkPassportCovid}
                              disableFIO={disableFIO}
                            />
                          )
                        }
                        {
                          isDocuments && (
                            <FormSection
                              label={DOCUMENTS}
                              inputs={(
                                <>
                                  <DocumentBlock
                                    setFieldValue={setFieldValue}
                                    inputProps={inputProps}
                                    documentType={values.documentType}
                                    onlyPassport={!isInsured}
                                    isResetDataOnChangeDocumentData
                                    selectSearch={this.setPassportValues(setFieldValue)}
                                  />
                                </>
                              )}
                            />
                          )
                        }
                        {
                          isAddress && (
                            <FormSection
                              label={REGISTRATION_ADDRESS}
                              inputs={(
                                <AddressBlockCommon
                                  inputProps={inputProps}
                                  isLivingAddress={false}
                                  insuredIsSame={isInsuredIsSame}
                                  isInsured={isInsured}
                                  isListBlackAddress={isListBlackAddress}
                                  selectSearch={this.setAddress(setFieldValue)}
                                  isManualEnter={values.isManualEnter}
                                  toggleTypeEnter={(value) => setFieldValue('isManualEnter', value)}
                                />
                              )}
                            />
                          )
                        }
                        {
                          isAddressLiving && (
                            <FormSection
                              label={LIVING_ADDRESS}
                              isShow={!values.livingAddressIsSame}
                              inputs={(
                                <AddressBlockCommon
                                  inputProps={inputProps}
                                  isLivingAddress
                                  insuredIsSame={isInsuredIsSame}
                                  isInsured={isInsured}
                                  insuredValues={insuredValues}
                                  selectSearch={this.setAddress(setFieldValue, true)}
                                  isListBlackAddress={isListBlackAddress}
                                  isManualEnter={values.isManualEnterLiving}
                                  toggleTypeEnter={(value) => setFieldValue('isManualEnterLiving', value)}
                                />
                              )}
                              checkBoxButton={() => this.renderAddressCheckbox(
                                inputProps,
                                setFieldValue,
                                isListBlackAddress,
                                blackAddressInfo,
                              )}
                            />
                          )
                        }
                        {
                          isInnSnilsBlock && (
                            <FormSection
                              inputs={InnSnilsBlock({
                                validateSnils: this.validateSnils,
                                inputProps,
                                snilsError,
                                hideInn,
                                hideSnils,
                              })}
                            />
                          )
                        }
                        {
                          isContacts && (
                            <FormSection
                              label={CONTACT_DATA}
                              inputs={
                                ContactBlock({
                                  inputProps,
                                  options: {
                                    isRequiredEmail: true,
                                    isRequiredMobile: true,
                                    isDisableMobile: Boolean(insurerFields?.mobilePhone),
                                  },
                                })
}
                            />
                          )
                        }
                        {
                          isBank && !hideBankInfo && (
                            <FormSection
                              label={BANK_DETAILS}
                              inputs={(
                                <BankBlock
                                  inputProps={inputProps}
                                  setFieldValue={setFieldValue}
                                  selectSearch={this.setBankValues(setFieldValue)}
                                  checkNumbers={checkNumbers}
                                />
                              )}
                            />
                          )
                        }
                      </>
                      )
                  }
                  <ErrorMessage message={errorWarning} />
                  <div className={b('formContainer', 'interface')}>
                    <TestDataGenerator
                      scenario={scenario}
                      type={formType}
                    />
                    <Button
                      type="submit"
                      className="insurerData__applyButton"
                      loading={isLoadingTerror}
                      disabled={isLoadingTerror}
                      onClick={() => {
                        this.validateForm(values, true);
                      }}
                    >
                      { NEXT }
                    </Button>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    );
  }
}

insurerData.propTypes = propTypes;
insurerData.defaultProps = defaultProps;

function mapStateToProps(state) {
  const {
    authReducer,
    agentData: {
      disableInsuredIsSame: isDisableInsuredIsSame,
      insured: {
        values: {
          personBlock: {
            birthDate: insuredBirthDate,
          },
        },
      },
      insurer: {
        values: {
          personBlock: {
            firstName: insurerFirstName,
            lastName: insurerLastName,
            patronymic: insurerPatronymic,
          },
          contactBlock: {
            mobilePhone: insurerMobilePhone,
          },
        },
      },
    },
    checkoutReducer: {
      checkAccountNumbersError,
      insurerValues,
      insuredValues,
      insuredIsSame: isInsuredIsSame,
      stage,
      productValues: {
        policySum,
        productPeriod,
        productVersion,
        regDate,
        birthDate,
      },
      productForm: {
        personType,
        hideInn,
        hideSnils,
        hideBankInfo,
        insurerIsInsured: isInsurerIsInsuredSetting,
        restrictions,
        productTerms,
        checkPassportCovid,
        insLineIszObjects,
      },
      scenario,
      isCopyContract,
    },
    helperReducer: {
      passportError,
      snilsError,
      addressValues,
    },
    validation: {
      isLoading: isLoadingTerror,
    },
  } = state;

  return {
    policySum,
    isInsurerIsInsuredSetting,
    hideBankInfo,
    checkAccountNumbersError,
    productTerms,
    restrictions,
    hideInn,
    hideSnils,
    checkPassportCovid,
    personType,
    userInfo: authReducer,
    passportError,
    snilsError,
    addressValues,
    insurerValues,
    insuredValues,
    isInsuredIsSame,
    stage,
    scenario,
    isLoadingTerror,
    productPeriod,
    regDate,
    insLineIszObjects,
    productVersion,
    birthDate,
    isDisableInsuredIsSame,
    insuredBirthDate,
    mainRequestId: getMainRequestId(state),
    insurerFields: {
      firstName: insurerFirstName,
      lastName: insurerLastName,
      patronymic: insurerPatronymic,
      mobilePhone: insurerMobilePhone,
    },
    isCopyContract,
  };
}

export default withCustomRouter(connect(
  mapStateToProps,
  {
    checkAgentAction: checkAgent,
    SetInsurerValues: setInsurerValues,
    SetInsuredValues: setInsuredValues,
    SetInsuredIsSame: setInsuredIsSame,
    ChangeDisableInsuredIsSame: changeDisableInsuredIsSame,
    GetError: getError,
    ResetValidationTerrorist: resetValidationTerrorist,
    checkNumbers: checkAccountNumbers,
  },
)(insurerData));
